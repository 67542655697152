import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import ConfirmationMixin from 'ember-onbeforeunload/mixins/confirmation';

// Actions
import { fillForm } from 'gigshq/actions/venues';

// Queries
import query from 'gigshq/graphql/queries/venue-edition';

export default Route.extend(ConfirmationMixin, {
  apollo: service(),
  can: service(),
  redux: service(),

  model({id}) {
    const variables = {id};

    return this.apollo.queryOperation()({query, variables}, 'viewer');
  },

  redirect({venue}) {
    if (this.can.cannot('edit venue', venue, {})) {
      return this.transitionTo('app.venues.index');
    }
  },

  afterModel({venue}) {
    if (!venue) return this.transitionTo('app.venues.index');

    const {
      id,
      name,
      description,
      streetAddress,
      city,
      country,
      postalCode,
      formattedAddress,
      profileImage,
      email,
      phoneNumber,
      latitude,
      longitude,
      capacityCount,
      venueCategory,
      openingHours,
      fbEventScrape,
      fbPostScrape,
      socialProfile
    } = venue;

    const values = {
      id,
      name,
      description,
      streetAddress,
      city,
      country,
      postalCode,
      formattedAddress,
      profileImage,
      email,
      phoneNumber,
      latitude,
      longitude,
      capacityCount,
      openingHours,
      fbEventScrape,
      fbPostScrape,
      socialProfile,
      venueCategoryId: venueCategory ? venueCategory.id : null
    };

    this.redux.dispatch(fillForm({form: 'venueEdition', values}));
  },

  isPageDirty() {
    const {
      venues: {
        venueEdition: {isDirty, isUploading}
      }
    } = this.redux.getState();
    return isDirty || isUploading;
  }
});
