import Component from '@ember/component';
import { computed } from '@ember/object';
import { equal } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { PropTypes as T } from 'ember-prop-types';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';
import arrayIncludes from 'gigshq/computed-macros/array-includes';

export default Component.extend(Localizable(translations), {
  accountManager: service('managers/account-manager'),
  selectedNetwork: 'facebook',

  propTypes: {
    socialProfile: T.looseShape({
      websiteUrl: T.nullable(T.string),
      facebookUrl: T.nullable(T.string),
      twitterUrl: T.nullable(T.string),
      instagramUrl: T.nullable(T.string),
      spotifyUrl: T.nullable(T.string),
      bandcampUrl: T.nullable(T.string),
      itunesUrl: T.nullable(T.string),
      soundcloudUrl: T.nullable(T.string),
      playMusicUrl: T.nullable(T.string),
      tidalUrl: T.nullable(T.string),
      youtubeUrl: T.nullable(T.string)
    }),
    networksToDisplay: T.array.isRequired,
    update: T.func.isRequired
  },

  networkValue: computed('selectedNetwork', 'socialProfile', function() {
    return this.get(`socialProfile.${this.selectedNetwork}Url`);
  }),

  networkLabel: computed('selectedNetwork', function() {
    return this.localTranslation(`labels.${this.selectedNetwork}`);
  }),

  networkPlaceholder: computed('selectedNetwork', function() {
    return this.localTranslation(`placeholders.${this.selectedNetwork}`);
  }),

  shouldDisplayWebsite: arrayIncludes('networksToDisplay', 'website'),
  shouldDisplayFacebook: arrayIncludes('networksToDisplay', 'facebook'),
  shouldDisplayTwitter: arrayIncludes('networksToDisplay', 'twitter'),
  shouldDisplayInstagram: arrayIncludes('networksToDisplay', 'instagram'),
  shouldDisplaySpotify: arrayIncludes('networksToDisplay', 'spotify'),
  shouldDisplayBandcamp: arrayIncludes('networksToDisplay', 'bandcamp'),
  shouldDisplayItunes: arrayIncludes('networksToDisplay', 'itunes'),
  shouldDisplaySoundcloud: arrayIncludes('networksToDisplay', 'soundcloud'),
  shouldDisplayPlayMusic: arrayIncludes('networksToDisplay', 'playMusic'),
  shouldDisplayTidal: arrayIncludes('networksToDisplay', 'tidal'),
  shouldDisplayYouTube: arrayIncludes('networksToDisplay', 'youtube'),

  isFacebookSelected: equal('selectedNetwork', 'facebook'),

  actions: {
    update(value) {
      const network = this.selectedNetwork;
      this.update(`${network}Url`, value);

      if (network === 'facebook' && value.trim() == '') {
        this.toggleFbEventScrape(false);
        this.toggleFbPostScrape(false);
      }
    },

    selectNetwork(network) {
      this.set('selectedNetwork', network);
      this.$()
        .find('input')
        .focus();
    }
  }
});
