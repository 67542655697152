import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { computed } from '@ember/object';
import { PropTypes as T } from 'ember-prop-types';
import AssetsCatalog from 'gigshq/constants/assets-catalog';
import Localizable from 'ember-cli-pod-translations/mixins/localizable';
import translations from './translations';

export default Component.extend(Localizable(translations), {
  i18n: service(),
  apollo: service(),
  fastboot: service(),

  propTypes: {
    venueCategories: T.array.isRequired,
    isDirty: T.bool.isRequired,
    isPending: T.bool.isRequired,
    errors: T.nullable(T.array).isRequired,
    data: T.nullable(T.object).isRequired,
    venueId: T.nullable(T.string).isRequired,
    name: T.string.isRequired,
    description: T.string.isRequired,
    streetAddress: T.string.isRequired,
    city: T.string.isRequired,
    country: T.string.isRequired,
    postalCode: T.string.isRequired,
    formattedAddress: T.string.isRequired,
    email: T.nullable(T.string).isRequired,
    phoneNumber: T.nullable(T.string).isRequired,
    latitude: T.number.isRequired,
    longitude: T.number.isRequired,
    capacityCount: T.nullable(T.number).isRequired,
    venueCategoryId: T.nullable(T.string).isRequired,
    profileImage: T.nullable(T.object).isRequired,
    socialProfile: T.object.isRequired,
    openingHours: T.array.isRequired,
    fbEventScrape: T.bool,
    fbPostScrape: T.bool,
    images: T.array,
    save: T.func.isRequired,
    setFormValue: T.func.isRequired,
    resetForm: T.func.isRequired,
    setFormSocialProfileValue: T.func.isRequired,
    markFormAsUploading: T.func,
    deleteImage: T.func
  },

  getDefaultProps() {
    return {
      images: [],
      markFormAsUploading: () => {},
      deleteImage: () => {}
    };
  },

  mascotState: computed('isDirty', 'errors', 'data', 'venueId', function() {
    if (!this.isDirty && this.venueId && this.data)
      return AssetsCatalog.GigiStates.THUMBS_UP.key;
    if (this.errors) return AssetsCatalog.GigiStates.PERPLEX.key;
    return AssetsCatalog.GigiStates.NEUTRAL.key;
  }),

  actionBoxText: computed('errors', function() {
    if (this.errors) {
      return this.localTranslation('action_box.error_text');
    }

    return this.localTranslation('action_box.editing_text');
  })
});
